/**
 * Base URL for the Cemex API, configured via environment variable, NEXT_ISSERVER da true en el server y undefined en client, esto nos permite que nunca falle a donde tiene que ir el endpoint.
 */
export const alertAIApiUrl: string | undefined = process.env.NEXT_ISSERVER
	? process.env.NEXT_SERVER_BACKEND_URL
	: process.env.NEXT_PUBLIC_BACKEND_URL;

/**
 * URL base para las APIs de UMA, configurada a través de una variable de entorno, sólo funciona client side, se utiliza para el vademecum
 */
const uma_apis_url = process.env.NEXT_ISSERVER
	? process.env.NEXT_SERVER_MEGALITH_URL
	: process.env.NEXT_PUBLIC_UMA_APIS_BASE_URL;

/**
 * URL for operation-related endpoints.
 */
export const operation_url = `${alertAIApiUrl}/operation`

/**
 * 
 * @param operationId 
 * @returns Para ver los tests
 */
export const test_by_id = (operationId: string) =>
	`${alertAIApiUrl}/test/byOperation/${operationId}`;


/**
 * URL for exporting operations.
 */
export const operation_export_url = `${operation_url}/export`;

/**
 * URL for closing operations.
 */
export const close_operation_url = `${operation_url}/close`;

/**
 * URL for checking the status of operations in progress.
 */
export const check_operation_url = `${operation_url}/inprogress`;

/**
 * URL for user-related endpoints.
 */
export const user_url = `${alertAIApiUrl}/user`;

/**
 * URL for fetching the list of drivers.
 */
export const driver_list_url = `${user_url}/driverList`;

/**
 * URL for fetching the list of breathalyzers.
 */
export const breathalyzer_url = `${alertAIApiUrl}/breathalyzer`;


/**
 * URL for fetching the list of roles.
 */
export const role_list_url = `${user_url}/roleList`;

/**
 * URL for fetching the list of bases.
 */
export const base_list_url = `${user_url}/baseList`;


/**
 * URL for fetching self-assestment.
 */
export const self_assestment_url = `${alertAIApiUrl}/self-assestment`;

/**
 * URL for the vademecum API endpoint.
 * This endpoint is used to fetch information about medications and their details.
 */
export const vademecum = `${uma_apis_url}/ai/vademecum`;

/**
 * URL for fetching questions.
 */
export const questions_url = `${alertAIApiUrl}/questions`
/**
 * URL for the physical exam endpoints.
 */
export const physical_exam_url = `${alertAIApiUrl}/physical-exam`;

/**
 * Base URL for AI models endpoints.
 */
const aiModelsUrl = `${alertAIApiUrl}/ai-models`;


/**
 * URL for the CV Fatigue detector AI model.
 */
export const cvFatigueDetectorUrl = `${aiModelsUrl}/fatigue-detector`;

/**
 * Endpoint base URL for the company controller.
 */
export const endpoint_base_company = `${alertAIApiUrl}/company`;


/**
 * Endpoint base URL for the substance-exam controller.
 */
export const endpoint_base_substance_exam = `${alertAIApiUrl}/substance-exam`;

/**
 * 
 * @param operationId id de la operación
 * Realiza una solicitud para iniciar la revisión de una operación específica.
 */
export const request_review = (operationId: string) =>
	`${alertAIApiUrl}/operation/${operationId}/request-review`;


export const user_me = `${alertAIApiUrl}/user/me`;

/**
 * Trae todas las operaciones del usuario
 */
export const operationsByMe = `${operation_url}/by-me`;

/**
 * 
 * Base url de controller bases
 */
export const bases_endpoints_url = `${alertAIApiUrl}/bases`
	
export const lastOperation = (uid: string) =>
	`${alertAIApiUrl}/operation/last-operation/${uid}`;


/**
 * Endpoint to get a specific company based on his id
 */
export const get_company = (companyId: string) =>
	`${alertAIApiUrl}/company/${companyId}`;
