import { close_operation_url, operation_export_url, operation_url } from '@/config/endpoints'
import { errorHandler } from '@/config/errorReporting'
import baseLogger from '@/config/logger'
import { objectToQueryParams, removeEmptyValuesFromObject } from '@/utils/functions'
import { IOperationFilters } from '../domain/OperationsTable'
import { IOperationRequest } from '../domain'
import { getFirebaseTokenFromCookies } from '@/modules/users/service/cookies'
import { useMutation, useQuery } from '@tanstack/react-query'

baseLogger.child({}, { msgPrefix: '[Operation requests] '})

export async function fetchOperationById(operationId: number|undefined) {
  // CSR
  try {
    const firebaseToken = await getFirebaseTokenFromCookies()
    const headers = {
      'Authorization': `Bearer ${firebaseToken}`
    }
    const allOperations = await fetch(`${operation_url}/${operationId}`, { cache: 'no-store', headers })
    if(!allOperations.ok) throw new Error(allOperations.statusText)

    return await allOperations.json()
  } catch (err){
    errorHandler?.report(`Error en request de obtener operación por ID: ${err}`);
    throw err;
  }
}

export function useFetchOperations({
	filters,
	page,
	exportCSV = false,
}: {
	filters?: IOperationFilters | null;
	page?: number | null;
	exportCSV?: boolean;
},) {
	return useQuery({
    queryKey: [filters, page, exportCSV],
		queryFn: async() => fetchOperations({filters, page, exportCSV}),
	});
}

export async function fetchOperations({filters, page, exportCSV = false}: {filters?: IOperationFilters | null, page?: number | null, exportCSV?: boolean}) {
  // CSR
  try {
    filters = filters || { driver: null, from: null, to: null, type: 'all', base: 0 }
    page = page || null
    const queryParams = objectToQueryParams({...removeEmptyValuesFromObject({...filters, page, export: exportCSV})})
    const firebaseToken = await getFirebaseTokenFromCookies()

    if (!firebaseToken){
      throw new Error('el firebaseToken esta vacio');
    }
		const headers = {
			Authorization: `Bearer ${firebaseToken}`,
		};
    const allOperations = await fetch(`${operation_url}?${queryParams}`, { cache: 'no-store', headers })
    if(!allOperations.ok) throw new Error(allOperations.statusText)

    return await allOperations.json()
  } catch (err){
    errorHandler?.report(`Error en request de obtener lista de operaciones: ${err}`);
    throw new Error(JSON.stringify(err))
  }
}

export async function fetchOperationsToExport(){
  try {
    const firebaseToken = await getFirebaseTokenFromCookies()
    const headers = {
      'Authorization': `Bearer ${firebaseToken}`
    }
    const operations = await fetch(`${operation_export_url}`, { cache: 'no-store', headers })
    if(!operations.ok) throw new Error(operations.statusText)

    return await operations.json()
  } catch (err){
    errorHandler?.report(`Error en request de obtener lista de operaciones para exportar: ${err}`);
    throw new Error(JSON.stringify(err))
  }
}

export async function closeOperation(operationId: number) {
  // CSR
  try {
      const closeOperationRequest : { operationId: number } = {
        operationId
      }
      const firebaseToken = await getFirebaseTokenFromCookies()
      const headers = {
        'Authorization': `Bearer ${firebaseToken}`,
        'Content-Type': 'application/json;charset=utf-8'
      }
      const operation = await fetch(
			`${close_operation_url}/${closeOperationRequest.operationId}`,
			{
				method: "PATCH",
				headers,
			}
		);

      if(!operation.ok) throw new Error(operation.statusText)

      return await operation.json();
      
  } catch (err) {
      errorHandler?.report(`Error en request de finalizar operación: ${err}`);
      throw new Error(JSON.stringify(err))
  }
}

export async function createOperationRequest(createOperationInfo : IOperationRequest) {
  // CSR
  try{
    const firebaseToken = await getFirebaseTokenFromCookies()
    const headers = {
      'Authorization': `Bearer ${firebaseToken}`,
      'Content-Type': 'application/json;charset=utf-8'
    }
    const operationRequest = await fetch(operation_url, {
      method: 'POST',
      headers,
      body: JSON.stringify(createOperationInfo)}
    )
    const operation = await operationRequest.json();
    if(!operationRequest.ok) throw new Error(operation.message)

    return operation;
  } catch (err) {
    errorHandler?.report(`Error en request que crea una operación: ${err}`)
    throw err;
  }
}